import { HttpResponse, graphql } from 'msw';
import { getCompanyFoundersMock } from 'components/Dashboard/Company/CompanyTeam/__mocks__/get_company_founders';
import ADD_PEOPLE_TO_WATCHLIST_WITH_CANONCIALS_MOCKED_RESPONSE from 'mocks/mock-data/add_peopel_to_watchlist_by_canonical';
import { GET_COMPANIES_IN_WATCHLIST_MOCK, GET_EMPY_COMPANIES_IN_WATCHLIST_MOCK } from 'mocks/mock-data/get_companies_in_watchlist';
import getCompanyEmployeesMockedResponse from 'mocks/mock-data/get_company_employees';
import { getCompanyMetricsWithDepartments } from 'mocks/mock-data/get_company_metrics';
import GET_COMPANY_PROFILE_HEADER_MOCKED_RESPONSE from 'mocks/mock-data/get_company_profile_header';
import { GET_COMPANY_WATCHLIST_MOCK } from 'mocks/mock-data/get_company_watchlist';
import { GET_PERSON_EDUCATION_MOCK } from 'mocks/mock-data/get_person_education';
import { GET_PERSON_EXPERIENCE_MOCK } from 'mocks/mock-data/get_person_experience';
import { GET_PERSON_PROFILE_HEADER_MOCK } from 'mocks/mock-data/get_person_profile_header';
import { GET_PERSON_USER_CONNECTIONS } from 'mocks/mock-data/get_person_user_connections';
import { GET_USER_PEOPLE_IMPORT_BY_ID_OR_URN_MOCKED_RESPONSE, GET_USER_PEOPLE_IMPORT_FAILED_MOCKED_RESPONSE, GET_USER_PEOPLE_IMPORT_PENDING_MOCKED_RESPONSE, GET_USER_PEOPLE_IMPORT_SUCCESSES_MOCKED_RESPONSE } from 'mocks/mock-data/get_user_people_import';
import GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST_MOCKED_RESPONSE from 'mocks/mock-data/get_user_people_imports_by_person_list';
import { ADD_COMPANIES_TO_WATCHLIST_MOCKED_RESPONSE } from 'queries/addCompaniesFromWatchlist';
import { ADD_PEOPLE_TO_WATCHLIST_MOCKED_RESPONSE } from 'queries/addPeopleToWatchlist';
import { CREATE_PEOPLE_WATCHLIST_MOCKED_RESPONSE } from 'queries/createPeopleWatchlist';
import { CREATE_WATCHLIST_MOCKED_RESPONSE } from 'queries/createWatchlist';
import { GET_PEOPLE_WATCHLIST_MOCKED_RESPONSE } from 'queries/getPeopleWatchlist';
import { GET_PEOPLE_WATCHLIST_DIGEST_CONFIG_MOCKED_RESPONSE } from 'queries/getPeopleWatchlistDigestConfig';
import { getPersonsByIdsMockData } from 'queries/getPersonsByIds';
import { GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS_MOCKED_RESPONSE } from 'queries/getSavedSearchNotificationSubscriptions';
import { GET_SIMILAR_COMPANIES_MOCKED_RESPONSE } from 'queries/getSimilarCompaniesQuery';
import { LIKE_COMPANIES_MOCKED_RESPONSE, UNLIKE_COMPANIES_MOCKED_RESPONSE } from 'queries/likeUnlikeCompanies';
import { TYPEAHEAD_ALL_QUERY_MOCKED_RESPONSE } from 'queries/typeahead';
import { UPDATE_PERSON_WATCHLIST_MOCKED_RESPONSE } from 'queries/updatePersonWatchlist';
import { UPDATE_WATCHLIST_MOCKED_RESPONSE } from 'queries/updateWatchlist';
import { UPSERT_COMPANY_WATCHLIST_USER_RELEVANCE_TYPE_MOCKED_RESPONSE } from 'queries/upsertCompanyWatchlistUserRelevanceType';
import { UPSERT_PEOPLE_WATCHLIST_DIGEST_CONFIG_MOCKED_RESPONSE } from 'queries/upsertPeopleWatchlistDigestConfig';
import { UPSERT_USER_VIEW_RECORD_FOR_COMPANY_MOCKED_RESPONSE } from 'queries/upsertUserViewRecordForCompany';
import { getCompanyFinancingV2 } from './mock-data/company-profile-v2/get_company_financing_v2';
import { getUserConnectionsV2 } from './mock-data/company-profile-v2/get_company_user_connections_v2';
import getSimilarCompaniesV2 from './mock-data/company-profile-v2/get_similar_companies_v2';
import { CREATE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE } from './mock-data/create_company_watchlist_custom_field';
import { CREATE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE } from './mock-data/create_people_watchlist_custom_field';
import { GET_ALL_LISTS_MOCKED_RESPONSE } from './mock-data/get_all_lists';
import getCompaniesAllEntityActions from './mock-data/get_companies_all_entity_actions';
import { getCompaniesByIdsMockData } from './mock-data/get_companies_by_ids';
import { getCompanyNotes } from './mock-data/get_company';
import { GET_COMPANY_SAVED_SEARCH_RESULTS_MOCKED_RESPONSE } from './mock-data/get_company_saved_search_results';
import { GET_COMPANY_WATCHLISTS_FOR_TEAM_MOCKED_RESPONSE } from './mock-data/get_company_watchlists_for_team';
import { MOCK_GET_CURRENT_USER } from './mock-data/get_current_user';
import { GET_EMPTY_WATCHLIST_WITH_PEOPLE_MOCKED_RESPONSE, GET_WATCHLIST_WITH_PEOPLE_MOCKED_RESPONSE } from './mock-data/get_people_watchlist_by_id_or_urn';
import getPeopleWatchlistsForTeam from './mock-data/get_people_watchlists';
import { GET_PERSON_SAVED_SEARCH_RESULTS_MOCKED_RESPONSE } from './mock-data/get_person_saved_search_results';
import { GET_COMPANY_SAVED_SEARCH_MOCKED_RESPONSE, GET_PEOPLE_SAVED_SEARCH_MOCKED_RESPONSE } from './mock-data/get_saved_search_mocked_response';
import { GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_MOCKED_RESPONSE } from './mock-data/get_use_fetch_paginated_saved_searches_with_user_options';
import { GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_COMPANIES_MOCKED_RESPONSE } from './mock-data/get_use_fetch_paginated_saved_searches_with_user_options_companies';
import { GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_PERSONS_MOCKED_RESPONSE } from './mock-data/get_use_fetch_paginated_saved_searches_with_user_options_persons';
import { GET_USE_FETCH_SAVED_SEARCHES_WITH_USER_OPTIONS_MOCKED_RESPONSE } from './mock-data/get_use_fetch_saved_searches_with_user_options';
import { getUseFetchWatchlists } from './mock-data/get_use_fetch_watchlists';
import { REMOVE_COMPANY_LIST_NAMED_VIEW_MOCKED_RESPONSE } from './mock-data/remove_company_list_named_view';
import { REMOVE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE } from './mock-data/remove_company_watchlist_custom_field';
import { REMOVE_PEOPLE_LIST_NAMED_VIEW_MOCKED_RESPONSE } from './mock-data/remove_people_list_named_view';
import { REMOVE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE } from './mock-data/remove_people_watchlist_custom_field';
import { SEARCH_COMPANIES_FIRST_PAGE } from './mock-data/search_companies';
import { SEARCH_PEOPLE_FIRST_PAGE } from './mock-data/search_people';
import { GET_ALL_TEAM_MEMBERS_BY_CUSTOMER_MOCKS } from './mock-data/settings/get_all_members_by_customer';
import { UPDATE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE } from './mock-data/update_company_watchlist_custom_field';
import { UPDATE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE } from './mock-data/update_people_watchlist_custom_field';
import { UPDATE_SAVED_SEARCH_MOCKED_RESPONSE } from './mock-data/update_saved_search';
import { UPSERT_COMPANY_LIST_NAMED_VIEW_MOCKED_RESPONSE } from './mock-data/upsert_company_list_named_view';
import { UPSERT_COMPANY_WATCHLIST_CUSTOM_FIELD_VALUE_MOCKED_RESPONSE } from './mock-data/upsert_company_watchlist_custom_field_value';
import { UPSERT_PEOPLE_LIST_NAMED_VIEW_MOCKED_RESPONSE } from './mock-data/upsert_people_list_named_view';
import { UPSERT_PEOPLE_WATCHLIST_CUSTOM_FIELD_VALUE_MOCKED_RESPONSE } from './mock-data/upsert_people_watchlist_custom_field_value';
const harmonicGraphQLAPI = graphql;
const PEOPLE_SAVED_SEARCH_ID = '117805';
const hasEmptyFilter = (filters, fieldName) => {
    return filters.some((filter) => !!filter.filterValue &&
        filter.field === fieldName &&
        filter.comparator !== 'isEmpty');
};
export default [
    harmonicGraphQLAPI.query('SearchCompaniesCount', () => {
        return HttpResponse.json({
            data: {
                searchCompaniesCount: {
                    count: 1864,
                    __typename: 'EntitySearchResponseConnection'
                }
            }
        });
    }),
    harmonicGraphQLAPI.query('SearchPeople', () => {
        return HttpResponse.json({
            data: {
                searchPeople: SEARCH_PEOPLE_FIRST_PAGE
            }
        });
    }),
    harmonicGraphQLAPI.query('GetAllLists', () => {
        return HttpResponse.json({
            data: GET_ALL_LISTS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetPaginatedUseFetchSavedSearches_WithUserOptions', ({ variables }) => {
        if (variables.type === 'PERSONS') {
            return HttpResponse.json({
                data: GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_PERSONS_MOCKED_RESPONSE
            });
        }
        else if (variables.type === 'COMPANIES_LIST') {
            return HttpResponse.json({
                data: GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_COMPANIES_MOCKED_RESPONSE
            });
        }
        return HttpResponse.json({
            data: GET_USE_FETCH_PAGINATED_SAVED_SEARCHES_WITH_USER_OPTIONS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetAllTeamMembersWithIntegratedEmailsByCustomer', () => {
        return HttpResponse.json({
            data: GET_USE_FETCH_SAVED_SEARCHES_WITH_USER_OPTIONS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetCompanyWatchlistsForTeam', () => {
        return HttpResponse.json({
            data: GET_COMPANY_WATCHLISTS_FOR_TEAM_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetPeopleWatchlistsForTeam', () => {
        return HttpResponse.json({
            data: GET_COMPANY_WATCHLISTS_FOR_TEAM_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetCurrentUser', () => {
        return HttpResponse.json({
            data: MOCK_GET_CURRENT_USER
        });
    }),
    harmonicGraphQLAPI.query('GetSimilarCompanies', () => {
        return HttpResponse.json({
            data: GET_SIMILAR_COMPANIES_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetPeopleWatchlist', () => {
        return HttpResponse.json({
            data: GET_PEOPLE_WATCHLIST_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('SearchCompaniesWithRelevance', () => {
        return HttpResponse.json({
            data: SEARCH_COMPANIES_FIRST_PAGE
        });
    }),
    harmonicGraphQLAPI.query('SearchCompanies', () => {
        return HttpResponse.json({
            data: SEARCH_COMPANIES_FIRST_PAGE
        });
    }),
    harmonicGraphQLAPI.query('GetWatchlistWithPeople', ({ variables }) => {
        if (hasEmptyFilter(variables.searchQuery.filterGroup.filters, 'person_custom_field_single_select')) {
            return HttpResponse.json({
                data: GET_EMPTY_WATCHLIST_WITH_PEOPLE_MOCKED_RESPONSE
            });
        }
        else {
            return HttpResponse.json({
                data: GET_WATCHLIST_WITH_PEOPLE_MOCKED_RESPONSE
            });
        }
    }),
    harmonicGraphQLAPI.query('GetCompaniesAllEntityActions', () => {
        return HttpResponse.json({
            data: {
                getCompaniesAllEntityActions
            }
        });
    }),
    harmonicGraphQLAPI.query('SearchCompaniesCount', () => {
        return HttpResponse.json({
            data: {
                searchCompaniesCount: {
                    count: 1864,
                    __typename: 'EntitySearchResponseConnection'
                }
            }
        });
    }),
    harmonicGraphQLAPI.query('TypeaheadSearchQuery', () => {
        return HttpResponse.json({ data: TYPEAHEAD_ALL_QUERY_MOCKED_RESPONSE });
    }),
    harmonicGraphQLAPI.query('GetPeopleWatchlists', () => {
        return HttpResponse.json({
            data: {
                getPeopleWatchlistsForTeam: getPeopleWatchlistsForTeam.getPeopleWatchlistsForTeam
            }
        });
    }),
    harmonicGraphQLAPI.query('GetUseFetchWatchlists', () => {
        return HttpResponse.json({
            data: {
                getCompanyWatchlistsForTeam: getUseFetchWatchlists.getCompanyWatchlistsForTeam
            }
        });
    }),
    harmonicGraphQLAPI.query('GetCompanyNotes', () => {
        return HttpResponse.json({ data: getCompanyNotes });
    }),
    harmonicGraphQLAPI.mutation('UpsertUserViewRecordsForCompanyUrns', () => {
        return HttpResponse.json(UPSERT_USER_VIEW_RECORD_FOR_COMPANY_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('UpsertPeopleWatchlistDigestConfig', () => {
        return HttpResponse.json(UPSERT_PEOPLE_WATCHLIST_DIGEST_CONFIG_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('UpsertCompanyWatchlistUserRelevanceType', () => {
        return HttpResponse.json(UPSERT_COMPANY_WATCHLIST_USER_RELEVANCE_TYPE_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('UpdateWatchlist', () => {
        return HttpResponse.json(UPDATE_WATCHLIST_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('UpdatePeopleWatchlist', () => {
        return HttpResponse.json(UPDATE_PERSON_WATCHLIST_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('LikeCompanies', () => {
        return HttpResponse.json(LIKE_COMPANIES_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('UnlikeCompanies', () => {
        return HttpResponse.json(UNLIKE_COMPANIES_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('CreateWatchlist', () => {
        return HttpResponse.json(CREATE_WATCHLIST_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('CreatePeopleWatchlist', () => {
        return HttpResponse.json({ data: CREATE_PEOPLE_WATCHLIST_MOCKED_RESPONSE });
    }),
    harmonicGraphQLAPI.mutation('AddCompaniesToWatchlist', () => {
        return HttpResponse.json(ADD_COMPANIES_TO_WATCHLIST_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.mutation('AddPeopleToWatchlistWithIds', () => {
        return HttpResponse.json(ADD_PEOPLE_TO_WATCHLIST_MOCKED_RESPONSE);
    }),
    harmonicGraphQLAPI.query('GetPeopleWatchlistDigestConfig', () => {
        return HttpResponse.json({
            data: GET_PEOPLE_WATCHLIST_DIGEST_CONFIG_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetSavedSearchNotificationSubscriptions', () => {
        return HttpResponse.json({
            data: GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetCompanySavedSearchResults', () => {
        return HttpResponse.json({
            data: GET_COMPANY_SAVED_SEARCH_RESULTS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetPersonSavedSearchResults', () => {
        return HttpResponse.json({
            data: GET_PERSON_SAVED_SEARCH_RESULTS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetSavedSearch', ({ variables }) => {
        if (variables.idOrUrn === PEOPLE_SAVED_SEARCH_ID) {
            return HttpResponse.json({
                data: GET_PEOPLE_SAVED_SEARCH_MOCKED_RESPONSE
            });
        }
        else {
            return HttpResponse.json({
                data: GET_COMPANY_SAVED_SEARCH_MOCKED_RESPONSE
            });
        }
    }),
    harmonicGraphQLAPI.query('GetCompanyWatchlist', () => {
        return HttpResponse.json({ data: GET_COMPANY_WATCHLIST_MOCK });
    }),
    harmonicGraphQLAPI.query('GetWatchlistWithCompaniesAndRelevance', () => {
        return HttpResponse.json({ data: GET_COMPANIES_IN_WATCHLIST_MOCK });
    }),
    harmonicGraphQLAPI.query('GetWatchlistWithCompanies', ({ variables }) => {
        if (hasEmptyFilter(variables.searchQuery.filterGroup.filters, 'company_custom_field_single_select')) {
            return HttpResponse.json({ data: GET_EMPY_COMPANIES_IN_WATCHLIST_MOCK });
        }
        else {
            return HttpResponse.json({ data: GET_COMPANIES_IN_WATCHLIST_MOCK });
        }
    }),
    // Company profile v2
    harmonicGraphQLAPI.query('GetCompanyFinancingV2', () => {
        return HttpResponse.json({ data: getCompanyFinancingV2 });
    }),
    harmonicGraphQLAPI.query('GetSimilarCompaniesV2', () => {
        return HttpResponse.json({ data: getSimilarCompaniesV2 });
    }),
    harmonicGraphQLAPI.query('GetCompanyProfileHeader', () => {
        return HttpResponse.json({
            data: GET_COMPANY_PROFILE_HEADER_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetCompanyMetrics', () => {
        return HttpResponse.json({ data: getCompanyMetricsWithDepartments });
    }),
    harmonicGraphQLAPI.query('GetCompanyEmployees', () => {
        return HttpResponse.json({ data: getCompanyEmployeesMockedResponse });
    }),
    harmonicGraphQLAPI.query('GetCompanyFounders', () => {
        return HttpResponse.json({ data: getCompanyFoundersMock });
    }),
    harmonicGraphQLAPI.query('GetCompanyUserConnectionsV2', () => {
        return HttpResponse.json({ data: getUserConnectionsV2 });
    }),
    harmonicGraphQLAPI.query('GetCompanyUserConnectionsWithOverviewAndEvents', () => {
        return HttpResponse.json({ data: getUserConnectionsV2 });
    }),
    //Settings
    harmonicGraphQLAPI.query('GetAllTeamMembersByCustomer', () => {
        return HttpResponse.json({ data: GET_ALL_TEAM_MEMBERS_BY_CUSTOMER_MOCKS });
    }),
    // Person profile
    harmonicGraphQLAPI.query('GetPersonProfileHeader', () => {
        return HttpResponse.json({ data: GET_PERSON_PROFILE_HEADER_MOCK });
    }),
    harmonicGraphQLAPI.query('GetPersonEducation', () => {
        return HttpResponse.json({ data: GET_PERSON_EDUCATION_MOCK });
    }),
    harmonicGraphQLAPI.query('GetPersonExperience', () => {
        return HttpResponse.json({ data: GET_PERSON_EXPERIENCE_MOCK });
    }),
    harmonicGraphQLAPI.query('GetPersonUserConnections', () => {
        return HttpResponse.json({ data: GET_PERSON_USER_CONNECTIONS });
    }),
    // Imports
    harmonicGraphQLAPI.query('GetUserPeopleImportByIdOrUrn', () => {
        return HttpResponse.json({
            data: GET_USER_PEOPLE_IMPORT_BY_ID_OR_URN_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetUserPeopleImportSuccesses', () => {
        return HttpResponse.json({
            data: GET_USER_PEOPLE_IMPORT_SUCCESSES_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetUserPeopleImportPending', () => {
        return HttpResponse.json({
            data: GET_USER_PEOPLE_IMPORT_PENDING_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetUserPeopleImportFailures', () => {
        return HttpResponse.json({
            data: GET_USER_PEOPLE_IMPORT_FAILED_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetUserPeopleImportsByPeopleListUrnOrId', () => {
        return HttpResponse.json({
            data: GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.query('GetCompaniesByIds', () => {
        return HttpResponse.json({ data: getCompaniesByIdsMockData });
    }),
    harmonicGraphQLAPI.query('GetPersonsByIds', () => {
        return HttpResponse.json({ data: getPersonsByIdsMockData });
    }),
    // Mutations
    harmonicGraphQLAPI.mutation('AddPeopleToWatchlistWithCanonicals', () => {
        return HttpResponse.json({
            data: ADD_PEOPLE_TO_WATCHLIST_WITH_CANONCIALS_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpdateSavedSearch', () => {
        return HttpResponse.json({
            data: UPDATE_SAVED_SEARCH_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('CreateCompanyWatchlistCustomField', () => {
        return HttpResponse.json({
            data: CREATE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('CreatePeopleWatchlistCustomField', () => {
        return HttpResponse.json({
            data: CREATE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpdateCompanyWatchlistCustomField', () => {
        return HttpResponse.json({
            data: UPDATE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpdatePeopleWatchlistCustomField', () => {
        return HttpResponse.json({
            data: UPDATE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('RemoveCompanyWatchlistCustomField', () => {
        return HttpResponse.json({
            data: REMOVE_COMPANY_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('RemovePeopleWatchlistCustomField', () => {
        return HttpResponse.json({
            data: REMOVE_PEOPLE_WATCHLIST_CUSTOM_FIELD_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpsertCompanyWatchlistCustomFieldValue', () => {
        return HttpResponse.json({
            data: UPSERT_COMPANY_WATCHLIST_CUSTOM_FIELD_VALUE_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpsertPeopleWatchlistCustomFieldValue', () => {
        return HttpResponse.json({
            data: UPSERT_PEOPLE_WATCHLIST_CUSTOM_FIELD_VALUE_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpsertCompanyListNamedView', () => {
        return HttpResponse.json({
            data: UPSERT_COMPANY_LIST_NAMED_VIEW_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('UpsertPeopleListNamedView', () => {
        return HttpResponse.json({
            data: UPSERT_PEOPLE_LIST_NAMED_VIEW_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('RemoveCompanyListNamedView', () => {
        return HttpResponse.json({
            data: REMOVE_COMPANY_LIST_NAMED_VIEW_MOCKED_RESPONSE
        });
    }),
    harmonicGraphQLAPI.mutation('RemovePeopleListNamedView', () => {
        return HttpResponse.json({
            data: REMOVE_PEOPLE_LIST_NAMED_VIEW_MOCKED_RESPONSE
        });
    })
];
